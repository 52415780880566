g_statusOn = false;		// Do not show Adobepass log
g_nlAdobePassUseRefreshless = false;
g_nlAdobePassUseMRSS = true;
// g_nlAdobePassOpenedMVPDWindow: Users have selected a MVPD provider manually. Only set to true in nlAdobePassProviderSelected(mvpdId){}
// in setAuthenticationStatus(1) it means the user just manually logged in, rather than auto login
var g_nlAdobePassAuthorizedResourceIds = null;
var g_assetID = "Internal use";
var g_UCI_GUID = "1000000";
var g_maxRating = null;
function initAdobePass()
{
	if(g_mvpdsConfig!=null)
	{
		g_mvpdsMap = new Object();
		for(var i=0;i<g_mvpdsConfig.mvpds.length;i++)
		{
			var s = g_mvpdsConfig.mvpds[i].status;
			if(s!=false)
			{
				var mvpdId = g_mvpdsConfig.mvpds[i].id;
				g_mvpdsMap[mvpdId] = g_mvpdsConfig.mvpds[i];
			}
		}
		// custom: assign src instead of call showSignInOptions to prevent UI blinking
		// document.getElementById("signInOptionsFrame").src = "/signinoptions";
			window.g_nlInitialized = true;
		// showSignInOptions(true);
		// hideSignIn();
		setTimeout("initAdobePassLater()",500);
	}
}
function initAdobePassLater()
{
	if(window.g_nlInitialized || document.getElementById("signInOptionsFrame").contentWindow.g_nlInitialized){
		// custom: use accessEnabler.js instead of swf
		nlLoadAdobePass(g_mvpdsConfig.env.pc, true, g_mvpdsConfig.softwareStatement);
	}else
		setTimeout("initAdobePassLater()",200);
}

function setAuthenticationStatus(isAuthenticated, errorCode)
{
	var ae = AEGetAccessEnabler();
	if(isAuthenticated==true || isAuthenticated==1 || isAuthenticated=="1")
	{
		nlHandleAdobePassMessage("Authenticated, obtaining authorization...", true);
		// custom: if on landing page, stop authorization
		// call getSelectedProvider
		// then in callback, set mvpd cookie and referesh
		g_nlAdobePassSelectedProviderReason = 'authorization';
		isMVPDLoggedIn = true;
		userType = 'mvpdsubscriber';
		// if(pageName.match(/^landing|home$/)){
		// 	g_callAdobePassMediaTokenHandler = false;
		// }
		ae.getSelectedProvider();
	}else{
		nlHandleAdobePassMessage("Authentication Failed: " + errorCode, true);
		if (document.cookie.indexOf('apmvpd=') >= 0)
		{
			nlAdobePassLogout();
		}
		if(g_nlAdobePassRefreshURL!=null)
			ae.getAuthentication(g_nlAdobePassRefreshURL);
		else
			ae.getAuthentication();
		// }
	}
}
function selectedProvider(obj)
{
	// Base is calling setSelectedProvider after authorization (setToken)
	// custom: this setSelectedProvider shouldn't related to authorization process
	// the provider is needed right after authenticated to set cookie, before call any authorization
	// get provider info first (for cookie use)
	// Only when click on specific program, start the authorization flow
	// if(g_callAdobePassMediaTokenHandler)
	// {
	// 	if(obj.MVPD!=null)
	// 	{
	// 		nlHandleAdobePassMediaToken(g_nlAdobePassMediaToken, g_nlAdobePassRequestedResourceID, obj.MVPD);
	// 		g_callAdobePassMediaTokenHandler = false;

	// 		var loggedIn = (nlAdobePassMVPDIDFromCookie()==null);
	// 		document.cookie = "apmvpd="+obj.MVPD+";path=/;domain="+((document.domain.split(".").length==2)?document.domain:document.domain.substr(document.domain.indexOf(".")));
	// 		nlHandleAdobePassAuthorized(obj, loggedIn);
	// 	}
	// }
	// else
	// {
	if (encodeURIComponent(obj.MVPD) && !g_mvpdsMap[encodeURIComponent(obj.MVPD)]) {
		g_nlAdobePassSelectedProviderReason = 'logout';
	}
		switch(g_nlAdobePassSelectedProviderReason)
		{
			case "authorization":
				if(obj.MVPD!=null)
				{
					var loggedIn = (nlAdobePassMVPDIDFromCookie()==null);
					mvpdProvider = encodeURIComponent(obj.MVPD);
					document.cookie =
                        "apmvpd=" + encodeURIComponent(obj.MVPD)
                        + ";path=/"
                        + ";domain=" + ((document.domain.split(".").length==2)?document.domain:document.domain.substr(document.domain.indexOf(".")))
                        + ";expires=" + (new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)).toUTCString();
					// Custom: if a) logged in manually or b) logged in from 3rd party site, then
					// 1, change the UI on the fly
					// 2, set values in the cookie
					// 3, after refreshing, the UI will be rendered from the server side, based on the cookies
					nlHandleAdobePassAuthorized(obj, loggedIn);
					// check preauthorized resource Ids from cookie
					if(authorizedResourceIds === ''){
						// if does not exists, call ae.checkPreauthorizedResources
						ae.checkPreauthorizedResources(g_nlAdobePassResourceIds);
					}else{
						// otherwise call the callback manually
						preauthorizedResources(authorizedResourceIds.split(','));
					}
					// page redirect
					// must be after cookie written
					// so server will know mvpd loggedin
					var cookie = document.cookie;
					// if(g_nlAdobePassOpenedMVPDWindow)	// (g_nlAdobePassOpenedMVPDWindow === true) meansif manually logged in
					if(cookie.indexOf('adobePassOpenedMVPDWindow=true') >= 0)
					{
					// When not using refreshless
					// Window will redirect to MVPD login page, then redirect back
					// Since using refresh, set g_nlAdobePassOpenedMVPDWindow = true will not work (the page refreshed, value back to false)
					// Use cookie.adobePassOpenedMVPDWindow instead
						document.cookie = "adobePassOpenedMVPDWindow=; expires=Thu, 01-Jan-1970 00:00:01 GMT;path=/;domain="+((document.domain.split(".").length==2)?document.domain:document.domain.substr(document.domain.indexOf(".")));
						g_nlAdobePassOpenedMVPDWindow = false;
						// nlHandleAdobePassDestroyIframe();
					}
				}
				break;
			case "logout":
				// clear mvpd related cookie
				document.cookie = "apmvpd=; expires=Thu, 01-Jan-1970 00:00:01 GMT;path=/;domain="+((document.domain.split(".").length==2)?document.domain:document.domain.substr(document.domain.indexOf(".")));
				document.cookie = "authorizedResourceIds=; expires=Thu, 01-Jan-1970 00:00:01 GMT;path=/;domain="+((document.domain.split(".").length==2)?document.domain:document.domain.substr(document.domain.indexOf(".")));
				document.cookie = "mvpdUserId=; expires=Thu, 01-Jan-1970 00:00:01 GMT;path=/;domain="+((document.domain.split(".").length==2)?document.domain:document.domain.substr(document.domain.indexOf(".")));
				document.cookie = "doubleChecked=; expires=Thu, 01-Jan-1970 00:00:01 GMT;path=/;";
				nlHandleAdobePassLoggedOut(obj);
				AEGetAccessEnabler().logout();
				break;
		}
		g_nlAdobePassSelectedProviderReason = null;
		g_nlAdobePassSelectedProvider = obj.MVPD;
	// }
}
function nlHandleAdobePassAuthorized(mvpd, loggedIn)
{
	g_statusOn = false;
	var mvpdObj = g_mvpdsMap[mvpd.MVPD];
}
function preauthorizedResources(resources)
{
	g_nlAdobePassAuthorizedResourceIds = resources;
	var authorized = false;
	var ae = AEGetAccessEnabler();
	if (resources != null)
	{
		var resourcesMap = new Object();
		for (var i = 0; i < resources.length; i++)
			resourcesMap[resources[i]] = true;

		for (var i = 0; i < g_nlAdobePassResourceIds.length; i++)
		{
			if (resourcesMap[g_nlAdobePassResourceIds[i]])
			{
				authorized = true;
				break;
			}
		}
		authorizedResourceIds = g_nlAdobePassAuthorizedResourceIds.join(',');
		// set cookie
		document.cookie = "authorizedResourceIds=" + encodeURIComponent(resources.join(',')) + ";path=/;domain=" + ((document.domain.split(".").length == 2) ? document.domain : document.domain.substr(document.domain.indexOf(".")));
	}
	if (authorized) {
		nlHandleAdobePassMessage('Preauthorized: ' + resources.join(','), true);
	} else {
		nlHandleAdobePassMessage('Authorization Cancelled', true);
	}
	// get UserId
	if(mvpdUserId === ''){
		// if does not exists, call ae.getMetaData
		// setTimeout(function(){
			ae.getMetadata('userID');
		// }, 100);
	}
}
/////////////////////////////  UI  ///////////////////////////////////
function displayProviderDialog(providers)
{
	// custom: only show login dialog when user click login
	// do not open in in authentication flow
	if(g_nlAdobePassOpenedMVPDWindow)
		nlHandleAdobePassProviders(providers);
}
function showSignInOptions(reload) {
	if (reload || document.getElementById("signInOptionsFrame").src == "") {
		var u = "signinoptions";
		if (location.search.indexOf("debug=true") != -1)
			u += "?debug=true";
		document.getElementById("signInOptionsFrame").src = LOC_SERVER + u;
	}
	// custom: remove UDN specific logic in base
	// custom: show sign in options
	// showModal("SignInOptions");
	showOverlay(".js-modal-all-signin");
}
function hideSignInOptions(){
	hideOverlay($(".js-modal-mvpd"));
}
function hideSignIn(){
	hideOverlay($(".js-modal-all-signin"));
}
function updateChannelResourceIds(containerId){
	if(pageName.match(/^home|channels|channel$/)){
		$(containerId).each(function(index, element){
			var uiChannelId = $(element).attr('data-id');
			var resourceIds = getChannelResourceId(uiChannelId);
			resourceIds.split(',').forEach(function(resourceId){
				$(element).addClass('tve-resource-id-' + resourceId);
			})
		});
	}
}
// get resourceId for specific channel
function getChannelResourceId(cid){
	var resourceIds = '';
	for(var resourceId in g_nlAdobePassResourceMap){
		var authorizedChannels = g_nlAdobePassResourceMap[resourceId].LINEAR_CHANNEL_PURCHASE;
		for(var channelId in authorizedChannels){
			if(cid == authorizedChannels[channelId]){
				resourceIds = resourceIds == '' ? resourceId : resourceIds + ',' + resourceId;
				break;
			}
		}
	}
	return resourceIds;
}
////////////////////////  Authorization Flow ////////////////////////////
function setToken(requestedResourceID, token)
{
	window.top.g_ae_authorizing = false;
	var ae = AEGetAccessEnabler();
	if(ae!=null)
	{
		ae.getMetadata('upstreamUserID');
		ae.getMetadata('mvpd');
		ae.getMetadata('zip');
	}
	if(g_nlAdobePassOpenedMVPDWindow)
	{
		g_nlAdobePassOpenedMVPDWindow = false;
		nlHandleAdobePassDestroyIframe();
	}

	var div = document.createElement("DIV");
   	var text = document.createTextNode(token.replace(/(.{20})/g,"$& "));
	div.appendChild(text);

	nlHandleAdobePassMessage("Authorized: " + div.innerHTML, true);

	g_nlAdobePassMediaToken = token;
	g_nlAdobePassRequestedResourceID = requestedResourceID;

	// custom: always call nlHandleAdobePassMediaToken()
	nlHandleAdobePassMediaToken(token, requestedResourceID, g_nlAdobePassSelectedProvider);
	// if(g_nlAdobePassIsJS)
	// {
	// 	g_callAdobePassMediaTokenHandler = true;
	// 	ae.getSelectedProvider();
	// }
	// else
	// 	nlHandleAdobePassMediaToken(token, requestedResourceID);
}

var RidConverter = {
	pattern: "/<channel><title>(.+?)<\/title>/",
	isMRssFormat: function (resourceId) {
		if (resourceId) {
			var result = resourceId.match(/<channel><title>(.+?)<\/title>/);
			if (result && result.length >= 2) {
				return true;
			}
		}
		return false;
	},

	/**
	 *
	 * @param resourceId
	 * @returns {null}	If resourceId is RSS format, return original resourceId. Otherwise, return null.
	 */
	getOriginalResourceId: function (resourceId) {
		var ret = null;
		if (g_nlAdobePassUseMRSS) {
			var result = resourceId.match(/<channel><title>(.+?)<\/title>/);
			if (result && result.length >= 2)
				ret = result[1];
		}
		return ret;
	}
};

var g_nlChromecastTokenRequested = false;
function nlHandleAdobePassMediaToken(token, requestedResourceID, mvpdId)
{
	var mvpdObj = null;
	if(mvpdId!=null)
		mvpdObj = g_mvpdsMap[mvpdId];
	else
		mvpdObj = g_mvpdsMap[AEGetAccessEnabler().getSelectedProvider().MVPD];

	if(mvpdObj!=null && mvpdObj.status!=null && mvpdObj.status)
	{
		//////  Chromecast  //////
		if (g_nlChromecastTokenRequested)
		{
			// The values of 'castObj.ppt' are encoded, because it comes from nlplayer and nlplayer requires encoded pptParams
			// But Chromecast receiver requires ppt object to be not encoded and it will do encode before calling ppt service.
			// So we need to decode values of 'ppt' object here.
			var ppt = {aptoken: token, aprid: requestedResourceID};
			if (RidConverter.isMRssFormat(requestedResourceID)) {
				ppt.apridrss = requestedResourceID;
				ppt.aprid = RidConverter.getOriginalResourceId(requestedResourceID);
			} else {
				// Workaround: If the rid is not rss format (from Chromecast)
				// FIXME: the apridrss will not have correct assetId and guid
				var apridrss = processMrss(requestedResourceID);
				if (requestedResourceID != apridrss) {
					// if it should, set it.
					ppt.apridrss = apridrss;
				}
			}
			updateChromecastSessionLater(ppt);
		}
	}
	else
		nlAdobePassLogout();
}
// custom setMetadataStatus to support userId
function setMetadataStatus(key, encrypted, data)
{
	if(key == 'upstreamUserID' && data!=null)
		g_nlUpstreamUserID = data;

	if(key == 'mvpd' && data!=null)
		g_nlMvpdId = data;
	// custom: set cookie
	if(key === 'userID'){
		mvpdUserId = data;
		document.cookie =
			"mvpdUserId=" + encodeURIComponent(mvpdUserId)
			+ ";path=/"
			+ ";domain=" + ((document.domain.split(".").length==2)?document.domain:document.domain.substr(document.domain.indexOf(".")))
			+ ";expires=" + (new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)).toUTCString();
		ga('set', 'dimension2', mvpdProvider);
		var mvpdName = (g_mvpdsMap[mvpdProvider] && g_mvpdsMap[mvpdProvider]['name']) ? g_mvpdsMap[mvpdProvider]['name'] : '';
		ga('set', 'dimension7', mvpdName);
		ga('send', 'event', {eventCategory: 'MVPD Login', eventAction: 'mvpd login complete', eventLabel: mvpdName});
        ////// custom: use NLTracker instead of ga.js
        // nlTrackMVPDAuthenticated(mvpd.MVPD);
        NLTracker.trackMVPDLogin(mvpdProvider, mvpdUserId, userType);

		// Custom: Verizon Tracking
		window.dotq = window.dotq || [];
		window.dotq.push(
				{
					'projectId': '10000',
					'properties': {
						'pixelId': '10114793',
						'qstrings': {
							'et': 'custom',
							'ec': 'login'
						}
					}
				});
	}
	if(key == 'maxRating' && data!=null) {
		g_maxRating = data;
	}
}

/**
 * Convert resourceId to MRSS format for MVPD Provider that use MRSS.
 * This function is for base adobepass.js use for backward compatible use.
 * @param resourceId
 * @returns {*}
 */
function getAdobePassCustomResourceId(resourceId){
	return processMrss(resourceId);
}

/**
 * Convert resourceId to MRSS format for MVPD Provider that use MRSS
 * @param resourceId
 * @param data	additional data
 * @returns {*}
 */
function processMrss(resourceId, data) {
	// Workaround: Make sure being called multiple times won't break
	if (RidConverter.isMRssFormat(resourceId)) {
		return resourceId;
	}
	if (data === undefined) {
		data = {};
	}
	var isTVRating = false,
			isMovieRating = false;
	if (data.tvRating) {
		for (var idx in TV_RATINGS) {
			if (TV_RATINGS[idx] == data.tvRating.toUpperCase()) {
				isTVRating = true;
				break;
			}
		}
		for (var idx in MOVIE_RATINGS) {
			if (MOVIE_RATINGS[idx] == data.tvRating.toUpperCase()) {
				isMovieRating = true;
				break;
			}
		}
	}
	var MRSS_xml = window.RESOURCEID_MRSS_MAP[mvpdProvider] ? window.RESOURCEID_MRSS_MAP[mvpdProvider]['rssId'] : window.RESOURCEID_MRSS_MAP['default']['rssId'];
	var MRSS_rating = data.tvRating ? MEDIA_RATING_MRSS.replace(/\${rating}/gi, data.tvRating)
			.replace(/\${ratingSchema}/gi, isTVRating ? "urn:v-chip" : isMovieRating ? "urn:mpaa" : "") : "";
	var MRSS_resourceId = MRSS_xml.replace(/\${resourceID}/gi, resourceId)
			.replace(/\${assetID}/gi, data.assetId || g_assetID)
			.replace(/\${UCI_GUID}/gi, data.guid || g_UCI_GUID)
			.replace(/\${mediaRating}/gi, MRSS_rating);
	return MRSS_resourceId;
}


function nlHandleAdobePassMessage(msg, debugOnly)
{
	if (!debugOnly || location.search.indexOf("&debug=true") != -1 || location.search.indexOf("?debug=true") != -1)
	{
		document.getElementById("signInOptionsFrame").contentWindow.nlHandleAdobePassMessage && document.getElementById("signInOptionsFrame").contentWindow.nlHandleAdobePassMessage(msg, debugOnly);
	}

	if(g_statusOn)
	{
		var str = null;
		if(msg.indexOf("Loading...")==0)
			str = "Loading...";
		else if(msg=="Initializing..." ||
				msg=="Authenticating..." ||
				msg=="Authorizing..." ||
				msg=="Authenticated, obtaining authorization..." ||
				msg.indexOf("Authorizing ")==0 ||
				msg.indexOf("Authenticated to ")==0)
			str = msg;
		else
			str = "";

		if(str!=null)
		{
			document.getElementById("playerContainer").innerHTML = '<div class="noAccess"><div class="noAccessTitle msg">'+str+'</div></div>';
		}
	}
}
